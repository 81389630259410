<template>
  <v-dialog
    max-width="1100"
    width="100%"
    :value="true"
    persistent
    @keydown.esc="close"
  >
    <v-card :loading="loading">
      <v-system-bar color="success" window>
        Einstellungen
        <v-spacer />
        <v-btn @click="close" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <v-list>
        <v-list-item>
          <v-row>
            <v-col
              class="text-body-2 my-auto"
              :class="settings.createNow ? 'primary--text' : ''"
              >Schüler*innen erstellen eigenen MA-Eintrag</v-col
            >
            <v-col>
              <DateInput v-model="settings.createStartDate" label="von" />
            </v-col>
            <v-col>
              <TimeInput v-model="settings.createStartTime" label="von" />
            </v-col>
            <v-col>
              <DateInput v-model="settings.createEndDate" label="bis" />
            </v-col>
            <v-col>
              <TimeInput v-model="settings.createEndTime" label="bis" />
            </v-col>
          </v-row>
        </v-list-item>
        <v-list-item>
          <v-row>
            <v-col
              class="text-body-2 my-auto"
              :class="settings.coachNow ? 'primary--text' : ''"
              >Betreuer:innen tragen sich bei Arbeit ein</v-col
            >
            <v-col>
              <DateInput v-model="settings.coachStartDate" label="von" />
            </v-col>
            <v-col>
              <TimeInput v-model="settings.coachStartTime" label="von" />
            </v-col>
            <v-col>
              <DateInput v-model="settings.coachEndDate" label="bis" />
            </v-col>
            <v-col>
              <TimeInput v-model="settings.coachEndTime" label="bis" />
            </v-col>
          </v-row>
        </v-list-item>

        <v-list-item>
          <v-row>
            <v-col
              class="text-body-2 my-auto"
              :class="settings.titleNow ? 'primary--text' : ''"
              >Titel änderbar (LK und Schüler:innen)</v-col
            >
            <v-col>
              <DateInput v-model="settings.titleStartDate" label="von" />
            </v-col>
            <v-col>
              <TimeInput v-model="settings.titleStartTime" label="von" />
            </v-col>
            <v-col>
              <DateInput v-model="settings.titleEndDate" label="bis" />
            </v-col>
            <v-col>
              <TimeInput v-model="settings.titleEndTime" label="bis" />
            </v-col>
          </v-row>
        </v-list-item>

        <v-list-item>
          <v-row>
            <v-col
              class="text-body-2 my-auto"
              :class="settings.assessorNow ? 'primary--text' : ''"
              >LK tragen sich für Zweitlesung ein</v-col
            >
            <v-col>
              <DateInput v-model="settings.assessorStartDate" label="von" />
            </v-col>
            <v-col>
              <TimeInput v-model="settings.assessorStartTime" label="von" />
            </v-col>
            <v-col>
              <DateInput v-model="settings.assessorEndDate" label="bis" />
            </v-col>
            <v-col>
              <TimeInput v-model="settings.assessorEndTime" label="bis" />
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-btn
          :disabled="loading || saving"
          :loading="loading"
          text
          @click="getData"
          >verwerfen</v-btn
        >
        <v-spacer />
        <v-btn text @click="close">abbrechen</v-btn>
        <v-btn
          color="primary"
          :disabled="loading || saving"
          text
          @click="saveData"
          >Speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { defineComponent } from "vue";
import DateInput from "common/components/DateInput.vue";
import TimeInput from "common/components/TimeInput.vue";
export default defineComponent({
  name: "ThesisEvento",
  components: { DateInput, TimeInput },
  data() {
    return {
      loading: false,
      saving: false,
      settings: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    close() {
      this.$router.back();
    },
    async getData() {
      this.loading = true;
      this.settings = await this.apiList({ resource: "thesis/settings" });
      this.loading = false;
    },
    async saveData() {
      this.saving = true;
      await this.apiPost({
        resource: "thesis/settings",
        data: this.settings,
      });
      this.saving = false;
      this.getData();
      this.$root.showSuccess("Änderungen erfolgreich gespeichert!");
    },
  },
});
</script>
